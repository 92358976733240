import React from 'react';
import PublicContainer from '../../containers/Public';

const styles: any = {
  main: {
    margin: '3%'
  }
}

const TheMainMainShowPrivacyPolicyPage = () => (
  <PublicContainer title="TheMainMainShow">
    <div style={styles.main}>    
        <h1 style={styles.mainHeader}>Privacy Policy</h1>
        <p>
        MainEventMoto knows that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly. This notice describes our privacy policy. By using MainEventMoto's services, you are accepting the practices described in this Privacy Notice.
        </p>
        <p>
        In general, MainEventMoto collects and uses information from all of our services to provide, maintain, protect and improve them, to develop new ones, and to protect MainEventMoto and our users.
        </p>

        <h2 style={styles.header}>What Personal Information About Customers Does MainEventMoto Gather?</h2>
        <p>
        Information You Give Us: Information you provide is used to facilitate administration and support of our products and your purchases. Your information is not provided to third-parties for purposes of profit. We may, however, engage a third-party to assist in site or app administration in the normal course of MainEventMoto business. They have access to the data to perform their functions, but may not use the data for other purposes.
        </p>

        <h2 style={styles.header}>Protection of MainEventMoto and Others</h2>
        <p>
        We release account and other personal information when we believe release is appropriate to comply with the law; or protect the rights, property, or safety of MainEventMoto, our users, or others.
        </p>

        <h2 style={styles.header}>With Your Consent</h2>
        <p>
        Other than as set out above, you will receive notice when information about you might go to third parties, and you will have an opportunity to choose not to share the information.
        </p>

        <h2 style={styles.header}>Automatic Information</h2>
        <p>
        Automatic information is collected for purposes of site and app administration and learning more about our customers’ interactions with our sites and apps so that we can continue to improve our products and your experience. We may also collect technical information to help us identify your device for fraud prevention and diagnostic purposes. This information will not be sold.
        </p>

        <h2 style={styles.header}>Information You Can Access</h2>
        <p>
        Examples of information you can access easily include personally identifiable information (including name, e-mail, password) on the MainEventMoto Dashboard.
        </p>

        <h2 style={styles.header}>Information you share</h2>
        <p>
        Many of our services let you share information with others through third-party applications such as Facebook, Twitter and YouTube. When you share information publicly, it may be indexable by search engines, and may be used by those third-party applications subject to their own privacy policies and terms of use. As you may choose to use third-party applications we make available through MainEventMoto's products and services, please make sure you read their individual privacy policies and terms of use so you can make sure you understand and accept how your information is being collected and used by them.
        </p>

        <h2 style={styles.header}>Changes</h2>
        <p>
        Our privacy policy may change from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of privacy policy changes). We will also keep prior versions of this Privacy Policy in an archive for your review.
        </p>

        <h2 style={styles.header}>Questions and Support</h2>
        <p>
        If you have questions about our privacy policy or need help with any of its provisions, please contact us by email. MainEventMoto@gmail.com
        </p>
    </div>
  </PublicContainer>
);

export default TheMainMainShowPrivacyPolicyPage;