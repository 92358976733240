import React from 'react';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    '& label': {
      color: '#9e9e9e',
    },
    '& input': {
      color: '#fff',
    },
    '& label.Mui-focused': {
      color: '#9e9e9e',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#9e9e9e',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9e9e9e',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#9e9e9e',
      },
      '&:hover fieldset': {
        borderColor: '#9e9e9e',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9e9e9e',
      },
    },
    borderBottom: '#fff',
    width: '100%'
  },
});

interface DateInputProps {
  label: string;
  value: string;
  onChange(event: any): void;
}

export default function DateInput(props: DateInputProps) {
  const classes = useStyles({});
  return <TextField
    id="datetime-local"
    label={props.label}
    value={props.value}
    type="datetime-local"
    onChange={props.onChange}
    margin="normal"
    className={classes.root}
  />
}