import React, { useState } from 'react';

import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/inputs/ActionButton';

const styles: any = {
  container: {
    border: '1px solid #ddd',
    borderRadius: 5
  }, 
  header: {
    fontSize: 16,
    padding: 5,
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    backgroundColor: '#f5f5f5',
    display: 'flex'
  },
  title: {
    paddingTop: 5
  },
  imgContainer: {
    paddingLeft: 20
  },
  img: {
    height: 25
  },
  body: {
    padding: 20
  }
}

interface PaymentContainerProps {
  title: string;
  img?: string;
  children: React.ReactNode;
}

const PaymentContainer: React.FC<PaymentContainerProps> = (props: PaymentContainerProps) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>{props.title}</div>
        { props.img &&
          <div style={styles.imgContainer}>
            <img src={props.img} alt="accepted cc's" style={styles.img} />
          </div>
        }
      </div>
      <div style={styles.body}>
        {props.children}
      </div>
  </div>
  )
}
export default PaymentContainer;