import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



interface DialogConfirmProps {
  value: string;
  open: boolean;
  onClose(): void;
  onChange(event: any): void;
}

export default function DialogConfirm(props: DialogConfirmProps) {

  return <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Password Protected</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Whats the password?
      </DialogContentText>
      <TextField
        value={props.value}
        onChange={props.onChange}
        autoFocus
        margin="dense"
        id="name"
        label="password"
        type="password"
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="primary">
        Submit
      </Button>
    </DialogActions>
  </Dialog>
}



