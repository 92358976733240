import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import TextInput from '../../../components/inputs/TextInput';
import CCInput from '../../../components/inputs/CCInput';
import ActionButton from '../../../components/inputs/ActionButton';
import PaymentContainer from '../containers/Payment';

const accepted = process.env.PUBLIC_URL + '/images/accepted_cc.png';

const styles: any = {
  container: {

  },
  buttonContainer: {
    marginTop: 20
  }
}

interface InvoiceProps {
  saving: boolean;
  invoiceNumber?: string;
  onClick(cardNumber: string, expirationDate: string, cvcCode: string, email: string): void;
}

const PaymentForm: React.FC<InvoiceProps> = (props: InvoiceProps) => {
  const [cardNumber, setCardNumber] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cvcCode, setCvcCode] = useState<string>('');

  const handleCvcCodeChange = (value: string) => {
    if (value.length <= 3) {
      setCvcCode(value);
    }
  }

  return (
    <div style={styles.container}>
      <PaymentContainer title="Payment" img={accepted} >
        <Grid container>
          <Grid item xs={12}>
            <CCInput 
              logo
              type="cc"
              placeholder="Valid card number"
              title="CARD NUMBER"
              result={cardNumber}
              onChange={(event) => setCardNumber(event.target.value)} 
            />
          </Grid>
            <Grid item xs={8}>
              <div style={{paddingRight: 20, paddingTop: 5}}>
                <CCInput
                  logo={false} 
                  type="exp"
                  placeholder="MM / YY"
                  title="EXPIRATION DATE"
                  result={expirationDate}
                  onChange={(event) => setExpirationDate(event.target.value)} 
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{paddingTop: 5}}>
                <TextInput 
                  maxLength={3}
                  type="number"
                  placeholder="CVC"
                  title="CV CODE"
                  result={cvcCode}
                  onChange={(event) => handleCvcCodeChange(event.target.value)} 
                />
              </div>
            </Grid>
          <Grid item xs={12}>
            <div style={{paddingTop: 5}}>
              <TextInput 
                type="string"
                placeholder=""
                title="RECEIPT EMAIL"
                result={email}
                onChange={(event) => setEmail(event.target.value)} 
              />
            </div>
          </Grid>
        </Grid>
        <div style={styles.buttonContainer}>

          <ActionButton 
            name="Submit Invoice" 
            onClick={() => props.onClick(cardNumber, expirationDate, cvcCode, email )} 
            fullWidth  
            disabled={props.saving}
          />
        </div>
      </PaymentContainer>
  </div>
  )
}
export default PaymentForm;