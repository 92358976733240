import React, { useState } from 'react';
import numeral from 'numeral';

import PaymentContainer from '../containers/Payment';
import { Invoice, Customer } from '../entities/payment';

const styles: any = {
  container: {

  },
  row: {
    display: 'flex',
    marginTop: 5
  },
  title: {
    color: '#555',
    fontSize: 14,
    textAlign: 'right',
    width: 100
  },
  value: {
    color: '#666',
    fontSize: 16,
    textAlign: 'left',
    paddingLeft: 20 
  }
}

interface InvoiceDetailProps {
  invoice: Invoice;
  customer: Customer;
}

const InvoiceDetail: React.FC<InvoiceDetailProps> = (props: InvoiceDetailProps) => {

  let amount = (props.invoice.amount / 100).toString();
  amount = numeral(amount).format('$0,0');
  
  return (
    <div style={styles.container}>
      <PaymentContainer title={`Invoice Details`}>
        <div style={styles.row}>
          <div style={styles.title}>INVOICE #:</div>
          <div style={styles.value}>{props.invoice.number}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.title}>CLIENT:</div>
          <div style={styles.value}>{props.customer.name}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.title}>DESCRIPTION:</div>
          <div style={styles.value}>{props.invoice.description}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.title}>REPEATING:</div>
          <div style={styles.value}>{(props.invoice.repeating ? 'true' : 'false')}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.title}>AMOUNT #:</div>
          <div style={styles.value}>{amount}</div>
        </div>
      </PaymentContainer>
  </div>
  )
}
export default InvoiceDetail;