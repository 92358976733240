import React from 'react';
import PublicSubContainer from '../../containers/PublicSub';

const directrfp = process.env.PUBLIC_URL + '/images/directrfp-small.png'
const sbm = process.env.PUBLIC_URL + '/images/sbm-small.png';
const kibooky = process.env.PUBLIC_URL + '/images/kibooky-small.png';
const booklaunch = process.env.PUBLIC_URL + '/images/booklaunch-small.png';
const pepsico = process.env.PUBLIC_URL + '/images/pepsico-small.png';
const careinnovations = process.env.PUBLIC_URL + '/images/careinnovations-small.png';
const frontier = process.env.PUBLIC_URL + '/images/frontier-small.png';


const styles: any = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  project: {
    width: 400,
    maringTop: 20,
    maringBottom: 20,
    textAlign: 'left',
    
  }
}

const projects = [
  {
    title: 'DirectRfp',
    tags: 'Website - Nodejs, React',
    description: 'Supported and implemented new highly scalable web components build on React.',
    link: 'http://www.directrfp.com/',
    img: directrfp
  },
  {
    title: 'SBM Site Services',
    tags: 'Website - Angular2, MVC, C#',
    description: 'Converted a MVC AngularJS web app to Angular2. Designed and architected several major components and was highly envolved with converting the app to a SPA application.',
    link: 'http://www.directrfp.com/',
    img: sbm
  },
  {
    title: 'Kibooky App',
    tags: 'Website and App - Nodejs, Backbonejs, Ionic Framework',
    description: 'Built a book admin to design avatars and build book pages. Designed and implemented an ionic mobile app to create custom avatars and view those avatars in a custom book.',
    link: 'http://www.kibooky.com/',
    img: kibooky
  },
  {
    title: 'Booklaunch',
    tags: 'Website - Nodejs, Backbonejs, Requirejs',
    description: 'Used nodejs and backbone to build a website where authors can build a professional book page in 10 mins or less.',
    link: 'http://booklaunch.io/',
    img: booklaunch
  },
  {
    title: 'Pepsico Spire',
    tags: 'Website - React, UWP, C#',
    description: 'Converted a WPF application to a UWP app using modern web frameworks.',
    link: 'http://pepsispire.com/',
    img: pepsico
  },
  {
    title: 'Careinnovations',
    tags: 'Website - MVC, C#',
    description: 'Designed and supported heathcare portals where clinicians can moniter home healthcare patients.',
    link: 'https://www.careinnovations.com/health-harmony/',
    img: careinnovations
  },
  {
    title: 'Frontier Performance Lubricants',
    tags: 'Website - SASS, Wordpress, Woocommerce',
    description: 'Designed, implemented and supported a custom wordpress website.',
    link: 'https://www.frontierlubricants.com/',
    img: frontier
  }
]

const ProjectsPage = () => (
  <PublicSubContainer title="projects">
    <div style={styles.main}>
      {projects.map((project) => (
        <div style={styles.project}>
          <div><img src={project.img} alt={project.title} /></div>
          <div>{project.title}</div>
          <div>{project.tags}</div>
          <div>{project.description}</div>
        </div>
      ))}
    </div>
  </PublicSubContainer>
);

export default ProjectsPage;
