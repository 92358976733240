import React from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import uuid from 'uuid/v1';
import moment from 'moment';
import { toast } from 'react-toastify';
import { sortBy } from 'lodash';

// ui
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// containers
import PublicContainer from '../../containers/Public';

// components
import TextInput from './components/Input';
import DateInput from './components/DateInput';
import DialogConfirm from './components/DialogConfirm';

const styles: any = {
  main: {
    position: 'relative',
    zIndex: 999,
    width: '50%',
    margin: '0px auto'
  },
  dropZone: {
    width: '100%',
    border: '2px dashed #ccc',
    padding: '40px 0 80px 0',
    textAlign: 'center',
    margin: '0px auto'
    
  },
  gridConatiner: {
    color: '#fff',
    backgroundColor: '#1E1E1E'
  },
  conatiner: {
    marginLeft: 20,
    padding: 20,
    color: '#fff'
  },
  formContainer: {
    color: '#fff'
  },
  label: {
    textAlign: 'right',
    color: '#9e9e9e'
  },
  value: {
    paddingLeft: 10,
    textAlign: 'left',
    overflowWrap: 'break-word'
  },
  link: {
    color: '#f50057'
  },
  header: {
    color: '#9e9e9e'
  },
  cell: {
    color: '#fff'
  }
}

interface Podcast {
  id: string;
  name: string;
  description: string;
  fileName: string;
  duration: number;
  createdAt: number;
  paid: number;
  liveAt: number;
}

interface HomePageState {
  loadingState: string;
  id: string;
  fileUrl: string;
  name: string;
  description: string;
  fileName: string;
  createdDate: number;
  error: string;
  liveAt: string;
  duration: number;
  podcasts: Podcast[];
  password: string;
  showPassword: boolean;
}

class TheMainMainShowUploadPage extends React.Component<{}, HomePageState> {
  constructor(props: {}) {
    super(props);

    let liveAt = moment().add(4, 'hour').format('YYYY-MM-DDTHH:mm');

    this.state = {
      loadingState: '',
      id: 'N/A',
      fileUrl: 'N/A',
      fileName: '',
      name: '',
      description: '',
      createdDate: 0,
      error: '',
      liveAt: liveAt,
      duration: 0,
      podcasts: [],
      password: '',
      showPassword: true
    };

  }

  componentDidMount = () => {
    this.fetchPodcasts();
  }

  getApiUrl = () => {
    if (window.location.host.indexOf('localhost') > -1) {
      return 'http://localhost:3003';
    } else {
      return 'https://eeqm2lk2ff.execute-api.us-west-2.amazonaws.com/dev';
    }
  }

  fetchPodcasts = () => {

    axios.get(`${this.getApiUrl()}/podcasts?status=all`).then((res) => {

      let podcastList = res.data;
      podcastList = sortBy(podcastList, 'createdAt');
      podcastList.reverse();
      this.setState({podcasts: podcastList});

    }).catch(() => {

      toast.error("Error fetching podcasts", {
        position: toast.POSITION.TOP_RIGHT
      });

    });

  }

  onDrop = (files: any) => {
    let file = files[0];

    // Split the filename to get the name and type
    let filePath = files[0].path;
    let fileParts = files[0].name.split('.');
    const fileNameOrig = fileParts[0];
    let fileName = fileParts[0];
    fileName = fileName.replace(/ /g,"_");
    filePath = filePath.replace(/ /g,"_");
    let fileType = fileParts[1];

    this.setState({loadingState: 'loading'});

    axios.post(`${this.getApiUrl()}/podcast/sign`, {
      fileName : fileName,
      fileType : fileType
    }).then((res) => {

      const url = res.data.url;
      const signedRequest = res.data.signedRequest;

      const options = {
        headers: {
          'Content-Type': `audio/${fileType}`
        }
      };
      axios.put(signedRequest, file, options).then(result => {
        
        const ts = Math.round((new Date()).getTime() / 1000);
        
        // format name
        const nameFormatted = fileNameOrig.replace('_', ' ');
        const fileNameParts = nameFormatted.split(' ');
        fileNameParts.splice(0, 2);
        const descriptionFormatted = fileNameParts.join(' ');


        const audio: any = document.createElement('AUDIO');
        audio.src = url;
        audio.addEventListener('loadedmetadata', () => {

          this.setState({
            loadingState: 'loaded',
            id: uuid(),
            fileUrl: url,
            fileName: filePath,
            name: nameFormatted,
            description: descriptionFormatted,
            createdDate: ts,
            duration: Math.round(audio.duration)
          });

          toast.success("File Uploaded", {
            position: toast.POSITION.TOP_RIGHT
          });

        });

      }).catch(error => {

        toast.error("Error uploading file", {
          position: toast.POSITION.TOP_RIGHT
        });

      })

    }).catch((error) => {

      toast.error("Error signing file", {
        position: toast.POSITION.TOP_RIGHT
      });

    });

  }

  clear = () => {
    this.setState({
      loadingState: '',
      id: '',
      fileUrl: '',
      fileName: '',
      name: '',
      description: ''
    })
  }

  handleNameChange = (event: any) => {
    this.setState({
      name: event.target.value,
    });
  }

  handleDescriptionChange = (event: any) => {
    this.setState({
      description: event.target.value,
    });
  }

  handleDateChange = (event: any) => {
    this.setState({
      liveAt: event.target.value,
    });
  }

  save = () => {
    // save
    const liveAt = moment(this.state.liveAt).unix();


    const data = {
      id: this.state.id,
      createdAt: this.state.createdDate,
      duration: this.state.duration,
      fileName: this.state.fileName,
      name: this.state.name,
      description: this.state.description,
      paid: 1,
      liveAt: liveAt
    }

    axios.post(`${this.getApiUrl()}/podcast`, data).then((res) => {

      toast.success("File saved!", {
        position: toast.POSITION.TOP_RIGHT
      });

      this.fetchPodcasts();

    }).catch((error) => {
      
      toast.error("Error saving file", {
        position: toast.POSITION.TOP_RIGHT
      });

    });

  }

  handlePasswordChange = (event: any) => {
    this.setState({
      password: event.target.value,
    });
  }

  handlePasswordClose = () => {
    if (this.state.password === 'cowboyssuck') {
      this.setState({
        showPassword: false
      });
    } else {
      this.setState({
        password: ''
      });
    }
  }

  render() {
    return (
      <PublicContainer title="Upload Podcast">
        <DialogConfirm
          open={this.state.showPassword}
          value={this.state.password}
          onChange={this.handlePasswordChange}
          onClose={this.handlePasswordClose}
        />
        { !this.state.showPassword &&
          <div>
            <div style={styles.main}>    
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Dropzone onDrop={acceptedFiles => this.onDrop(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => (
                      <section >
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div style={styles.dropZone}>Drag 'n' drop some files here, or click to select files</div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <div>
                    { this.state.loadingState === 'loading' &&
                      <div style={{marginTop: 10}}>
                        <div>Uploading...</div>
                        <LinearProgress color="secondary" />
                        <div style={{fontSize: 10}}>Large files might take a few minutes</div>
                      </div>
                    }
                  </div>
                </Grid>
                <Grid item xs={8} style={styles.gridConatiner}>
                  <div style={styles.conatiner}>
                      <div style={styles.formContainer}>
                        <form noValidate autoComplete="off">
                          <Grid container>
                            <Grid item xs={3}>
                              <div style={styles.label}>ID: </div>
                              <div style={styles.label}>Created Date: </div>
                              <div style={styles.label}>Duration (secs): </div>
                              <div style={styles.label}>Url: </div>
                            </Grid>
                            <Grid item xs={9}>
                              <div style={styles.value}>{this.state.id}</div>
                              <div style={styles.value}>{this.state.createdDate}</div>
                              <div style={styles.value}>{this.state.duration}</div>
                              <div style={styles.value}><a href={this.state.fileUrl} style={styles.link} target="_blank" rel="noopener noreferrer">{this.state.fileUrl}</a></div>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                              <div>
                                <TextInput 
                                  label="Name"
                                  value={this.state.name}
                                  onChange={this.handleNameChange}
                                />
                              </div>
                              <div>
                                <TextInput 
                                  label="Description"
                                  value={this.state.description}
                                  onChange={this.handleNameChange}
                                />
                              </div>
                              <div>
                                <DateInput 
                                  label="Live Date"
                                  value={this.state.liveAt}
                                  onChange={this.handleDateChange}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <div style={{marginTop: 10}}>
                            <Button fullWidth onClick={this.save} color="secondary">Save Podcast</Button>
                          </div>
                        </form>
                      </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div style={{width: '60%', margin: '0px auto', color: '#fff', marginTop: 20}}>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={styles.header}>id</TableCell>
                        <TableCell style={styles.header}>name</TableCell>
                        <TableCell style={styles.header}>description</TableCell>
                        <TableCell style={styles.header}>filename</TableCell>
                        <TableCell style={styles.header}>duration</TableCell>
                        <TableCell style={styles.header}>created</TableCell>
                        <TableCell style={styles.header}>live</TableCell>
                        <TableCell style={styles.header}>paid</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.podcasts.map(podcast => (
                        <TableRow key={podcast.id}>
                          <TableCell style={styles.cell}>{podcast.id}</TableCell>
                          <TableCell style={styles.cell}>{podcast.name}</TableCell>
                          <TableCell style={styles.cell}>{podcast.description}</TableCell>
                          <TableCell style={styles.cell}>{podcast.fileName}</TableCell>
                          <TableCell style={styles.cell}>{podcast.duration}</TableCell>
                          <TableCell style={styles.cell}>{podcast.createdAt}</TableCell>
                          <TableCell style={styles.cell}>{podcast.liveAt}</TableCell>
                          <TableCell style={styles.cell}>{podcast.paid}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>
          </div>
        }
      </PublicContainer>
    );
  }
}

export default TheMainMainShowUploadPage;