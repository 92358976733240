import React from 'react';
import PublicContainer from '../../containers/Public';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const logo = process.env.PUBLIC_URL + '/images/brain-2.png';

const styles: any = {
  main: {
    color: '#666', 
    // backgroundColor: 'green',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1
  },
  img: {
    marginLeft: 20,
    marginRight: 20
  },
  link: {
    justifyContent: 'flex-start',
    top: 0,
    textAlign: 'left'
  },
  linkFirst: {
    borderBottom: '1px solid #666',
    width: 150,
    justifyContent: 'flex-end',
    display: 'flex',
    fontWeight: 'bold'
  },
  linkMiddle: {
    borderBottom: '1px solid #666',
    width: 200,
    marginTop: 60,
    marginBottom: 60
  },
  linkLast: {
    borderBottom: '1px solid #666',
    width: 150,
    justifyContent: 'flex-end',
    display: 'flex'
  },

  linkFirstRight: {
    borderBottom: '1px solid #666',
    width: 150,
    justifyContent: 'flex-start',
    display: 'flex'
  },
  linkMiddleRight: {
    borderBottom: '1px solid #666',
    width: 200,
    marginTop: 60,
    marginBottom: 60,
    justifyContent: 'flex-end',
    display: 'flex'
  },
  linkLastRight: {
    borderBottom: '1px solid #666',
    width: 150,
    justifyContent: 'flex-start',
    display: 'flex'
  }

}

interface HomePageProps {
  history: any;
}

interface HomePageState {
  open: boolean;
}

class HomePage extends React.Component<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidMount = () => {
    // load
  }

  handleClick = (route: string) => {
    this.props.history.push(route);
  }

  handleMenuClick = () => {
    const openToggle = !this.state.open;
    this.setState({open: openToggle})
  }

  render() {

    return (
      <PublicContainer title="graymatterdev">
        <div style={styles.main}>
          <Hidden smDown>
            <div >
              <div style={styles.linkFirst}>
                <div style={styles.link} className="link" onClick={() => this.handleClick('/contact')}>Contact</div>
              </div>
              <div style={styles.linkMiddle}>
                <div style={styles.link} className="link" onClick={() => this.handleClick('/about')}>About</div>
              </div>
              <div style={styles.linkLast}>
                <div style={styles.link} className="link" onClick={() => this.handleClick('/projects')}>Projects</div>
              </div>
            </div>
          </Hidden>
          <Hidden mdUp>
            <IconButton edge="start" color="inherit" aria-label="Menu" onClick={this.handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="long-menu"
              open={this.state.open}
              onClose={this.handleMenuClick}
            >
              <MenuItem onClick={() => this.handleClick('/contact')}>Contact</MenuItem>
              <MenuItem onClick={() => this.handleClick('/about')}>About</MenuItem>
              <MenuItem onClick={() => this.handleClick('/projects')}>Projects</MenuItem>
              <MenuItem onClick={() => this.handleClick('/themainmainshow/terms-of-use')}>TheMainMainShow</MenuItem>
            </Menu>
          </Hidden>
          <div style={styles.img}>
              <img src={logo} alt="graymaterdev logo" />
          </div>
          <Hidden smDown>
            <div>
              <div style={styles.linkFirstRight}>
                <div style={styles.link} className="link" onClick={() => this.handleClick('/themainmainshow/terms-of-use')}>TheMainMainShow</div>
              </div>
              <div style={styles.linkMiddleRight}>
                <div style={styles.link} className="link">BeFitDavis</div>
              </div>
              <div style={styles.linkLastRight}>
                <div style={styles.link} className="link">Tootr</div>
              </div>
            </div>
          </Hidden>
        </div>
      </PublicContainer>
    );
  }
}


export default HomePage;
