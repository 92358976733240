import React, { Component } from 'react';
import { Snackbar, Container, IconButton, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles: any = {
  container: {
  },
  title: {
    color: '#8AADA2',
    fontSize: 64,
    marginTop: '3%',
    marginBottom: '3%'
  },
  titleSmall: {
    color: '#8AADA2',
    fontSize: 42,
    marginTop: '3%',
    marginBottom: '3%'
  }
};

interface PublicLayoutProps {
  title?: string;
  msg?: string;
}

interface PublicLayoutState {
  open: boolean;
}

class PublicLayout extends Component<PublicLayoutProps, PublicLayoutState> {

  constructor(props: PublicLayoutProps) {
    super(props);
    this.state = {
      open: false
    }
  }
  
  componentDidUpdate = (prevProps:any, prevState:any) => {
    if (prevProps.msg !== this.props.msg) {
      this.setState({open: true});
    }
  }

  handleClose = () => {
    this.setState({open: false});
  }

  render() {
    const title = this.props.title || 'graymatterdev';
    return (
      <div style={styles.container}>
        <div className="content">
          <Hidden smDown>
            <div style={styles.title}>{title}</div>
          </Hidden>
          <Hidden mdUp>
            <div style={styles.titleSmall}>{title}</div>
          </Hidden>
          {this.props.children}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.props.msg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}
export default PublicLayout;
