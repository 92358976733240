import React from 'react';
import PublicSubContainer from '../../containers/PublicSub';

const styles: any = {
  main: {

  }
}

const ContactPage = () => (
  <PublicSubContainer title="contact">
    <div className={styles.main}>
      The contact page
    </div>
  </PublicSubContainer>
);

export default ContactPage;
