import HomePage from '../pages/home/Home';
import AboutPage from '../pages/about/About';
import ContactPage from '../pages/contact/Contact';
import ProjectsPage from '../pages/projects/Projects';

import TheMainMainShowTermsPage from '../pages/themainmainshow/TermsAndConditions';
import TheMainMainShowPrivacyPolicyPage from '../pages/themainmainshow/PrivacyPolicy';
import TheMainMainShowUploadPage from '../pages/themainmainshow/Upload';

import PaymentPage from '../pages/payment/Payment';

const routes = {
  public: [
    {
      name: 'Home',
      path: '/',
      exact: true,
      component: HomePage
    },
    {
      name: 'About',
      path: '/about',
      exact: true,
      component: AboutPage
    },
    {
      name: 'Contact',
      path: '/contact',
      exact: true,
      component: ContactPage
    },
    {
      name: 'Projects',
      path: '/projects',
      exact: true,
      component: ProjectsPage
    },
    {
      name: 'TheMainMainShow',
      path: '/themainmainshow/terms-of-use',
      exact: true,
      component: TheMainMainShowTermsPage
    },
    {
      name: 'TheMainMainShow',
      path: '/themainmainshow/privacy-policy',
      exact: true,
      component: TheMainMainShowPrivacyPolicyPage
    },
    {
      name: 'TheMainMainShow',
      path: '/themainmainshow/upload',
      exact: true,
      component: TheMainMainShowUploadPage
    },
    {
      name: 'Payment',
      path: '/payment',
      exact: true,
      component: PaymentPage
    },
    {
      name: 'Payment',
      path: '/payment/:id',
      exact: true,
      component: PaymentPage
    },
  ],
  private: [

  ]
}

export default routes;