import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import MaskedInput from 'react-text-mask';
import CreditCard from '@material-ui/icons/CreditCard';

const customStyles = (theme: any) => ({
  textFieldRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  textFieldInput: {
    borderRadius: '4px 0px 0px 4px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: '100%',
    fontWeight: 600,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
  textFieldInputMulti: {
    borderRadius: '4px 0px 0px 4px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: '100%',
    minHeight: 150,
    fontWeight: 600,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
  textFieldInputSelected: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '3px solid ' + green[500],
    fontSize: 16,
    padding: '15px 17px',
    width: '100%'
  },
  textFieldFormLabel: {
    fontSize: 18,
  },
  ccContainer: {
    display: 'inline-flex',
    width: '100%',
  },
  textInputContainer: {
    width: '100%'
  },
  iconContainer: {
    backgroundColor: '#29ABE2',
    color: '#fff',
    borderRadius: '0px 4px 4px 0px',
    cursor: 'pointer',
    width: 40,
    paddingLeft: 10,
    paddingTop: 10
  },
});

interface TextInputProps {
    classes?: any;
    selected?: boolean;
    placeholder?: string;
    result: string | number;
    type: string;
    logo: boolean;
    readOnly?: boolean;
    width?: number;
    title?: string;
    onChange(event: any): void;
}

const TextMaskCC = (props: any) => {
  const { inputRef, ...other } = props;

  const pattern = [ /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={pattern}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

const TextMaskExp = (props: any) => {
  const { inputRef, ...other } = props;

  const pattern = [ /\d/, /\d/, '/', /\d/, /\d/ ];

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={pattern}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

class CCInput extends Component<TextInputProps> {
  render() {
    const { classes, selected } = this.props;
    const name = this.props.placeholder || this.props.title;

    const inputProps: any = {
      readOnly: this.props.readOnly,
      disableUnderline: true,
      classes: {
        root: classes.textFieldRoot,
        input: classes.textFieldInput,
      }
    };

    if (this.props.type === 'cc') {
      inputProps.inputComponent = TextMaskCC;
    }

    if (this.props.type === 'exp') {
      inputProps.inputComponent = TextMaskExp;
    }
    
    return (
      <div className={classes.searchContainer} >
        <div className={classes.textInputContainer} style={(this.props.width !== undefined ? {width: this.props.width} : {})}>
          { this.props.title !== undefined &&
            <div style={{fontSize: 14, textAlign: 'left', color: '#777'}}>{this.props.title}</div>
          }
          <div className={classes.ccContainer}>
            <div className={classes.textInputContainer}>
              <TextField
                type="string"
                defaultValue=""
                id={`bootstrap-input-${this.props.type}-${name}`}
                value={this.props.result}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                InputProps={inputProps}
                InputLabelProps={{
                  shrink: true,
                  className: classes.textFieldFormLabel,
                }}
                fullWidth
              />
            </div>
            { this.props.logo && 
              <div className={classes.iconContainer}>
                <CreditCard />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(customStyles)(CCInput);