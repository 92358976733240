import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const logo = process.env.PUBLIC_URL + '/images/brain-2.png';

const styles: any = {
  container: {
    position: 'relatve'
  },
  content: {
    marginTop: 20
  },
  headerContainer: {
    marginTop: '2%'
  },
  header: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },
  title: {
    color: '#8AADA2',
    fontSize: 24,
    marginTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'left'
  },
  img: {

  },
  menu: {
    display: 'flex',
    marginTop: 5
  },
  link: {
    paddingLeft: 20,
    paddingRight: 20
  },
  linkSelected: {
    paddingLeft: 20,
    paddingRight: 20,
    borderBottom: '1px solid #666'
  },
  titleSmall: {
    color: '#8AADA2',
    fontSize: 42,
    marginTop: '3%',
    marginBottom: '3%'
  }
};

interface PublicSubLayoutProps {
  title?: string;
  history?: any;
}

class PublicSubLayout extends Component<PublicSubLayoutProps, {}> {

  handleClick = (route: string) => {
    this.props.history.push(route);
  }

  render() {
    const title = this.props.title || 'graymatterdev';
    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <div style={styles.header}>
            <div style={styles.img}>
              <img src={logo} width={50} alt="graymatter dev logo" />
            </div>
            <div>
              <div style={styles.title}>{title}</div>
              <div style={styles.menu}>
                <div style={styles.link} className="link" onClick={() => this.handleClick('/')}>home</div>
                <div style={(this.props.title === 'contact' ? styles.linkSelected : styles.link)} className="link" onClick={() => this.handleClick('/contact')}>contact</div>
                <div style={(this.props.title === 'about' ? styles.linkSelected : styles.link)} className="link" onClick={() => this.handleClick('/about')}>about</div>
                <div style={(this.props.title === 'projects' ? styles.linkSelected : styles.link)} className="link" onClick={() => this.handleClick('/projects')}>projects</div>
              </div>
            </div>
            <div>
              
            </div>
          </div>
        </div>
        <div style={styles.content}>
          
          {this.props.children}
        </div>
      </div>
    );
  }
}
export default withRouter<any>(PublicSubLayout);
