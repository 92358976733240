import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import MaskedInput from 'react-text-mask';

const customStyles = (theme: any) => ({
  textFieldRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  textFieldInput: {
    borderRadius: '4px 0px 0px 4px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: '100%',
    fontWeight: 600,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
  textFieldInputMulti: {
    borderRadius: '4px 0px 0px 4px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: '100%',
    minHeight: 150,
    fontWeight: 600,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
  textFieldInputSelected: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '3px solid ' + green[500],
    fontSize: 16,
    padding: '15px 17px',
    width: '100%'
  },
  textFieldFormLabel: {
    fontSize: 18,
  },
  textInputContainer: {
    width: '100%'
  },
  searchContainer: {
    display: 'inline-flex',
    width: '100%',
  }
});

interface TextInputProps {
    classes?: any;
    selected?: boolean;
    placeholder?: string;
    type: string;
    result: string | number;
    readOnly?: boolean;
    width?: number;
    title?: string;
    multiline?: boolean;
    multilineMaxRows?: number;
    maxLength?: number;
    onChange(event: any): void;
}

const TextMaskCustom = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class TextInput extends Component<TextInputProps> {
  render() {
    const { classes, selected } = this.props;
    const name = this.props.placeholder || this.props.title;
    const multiline = this.props.multiline || false;
    const multilineMaxRows = this.props.multilineMaxRows || 10;

    const inputProps: any = {
      readOnly: this.props.readOnly,
      disableUnderline: true,
      maxLength: this.props.maxLength || 200,
      classes: {
        root: classes.textFieldRoot,
        input: (multiline ? classes.textFieldInputMulti : classes.textFieldInput),
      }
    };

    if (this.props.type === 'phone') {
      inputProps.inputComponent = TextMaskCustom;
    }
    
    return (
      <div className={classes.searchContainer} >
        <div className={classes.textInputContainer} style={(this.props.width !== undefined ? {width: this.props.width} : {})}>
          { this.props.title !== undefined &&
            <div style={{fontSize: 14, textAlign: 'left', color: '#777'}}>{this.props.title}</div>
          }
          <TextField
            type={(this.props.type === 'phone' ? 'string' : this.props.type)}
            defaultValue=""
            id={`bootstrap-input-${this.props.type}-${name}`}
            value={this.props.result}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            InputProps={inputProps}
            InputLabelProps={{
              shrink: true,
              className: classes.textFieldFormLabel
            }}
            fullWidth
            multiline={multiline}
            rowsMax={multilineMaxRows}
            />
        </div>
      </div>
    )
  }
}

export default withStyles(customStyles)(TextInput);