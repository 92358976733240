import React from 'react';
import DocumentTitle from 'react-document-title';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Particles from 'react-particles-js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import routes from './config/routes';

const App: React.FC = () => {
  return (
    <DocumentTitle title="graymatterdev">
      <Router>
        <div className="App">
          <main>
            <Switch>
              {routes.private.map((route: any) =>
                <Route 
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component} 
                  roles={route.roles} />
              )}
              {routes.public.map((route: any) =>
                <Route 
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component} />
              )}
            </Switch>
          </main>
        </div>
        <Particles canvasClassName="background" params={{
            "particles": {
                "number": {
                    "value": 50
                },
                "size": {
                    "value": 3
                }
            },
            "interactivity": {
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": "repulse"
                    }
                }
            }
        }} />
        <ToastContainer />
      </Router>
    </DocumentTitle>
  );
}

export default App;
