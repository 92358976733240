import React, { useState, useEffect } from 'react';

import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/inputs/ActionButton';

import PaymentContainer from '../containers/Payment';

const styles: any = {
  container: {

  },
  buttonContainer: {
    marginTop: 20
  }
}

interface InvoiceProps {
  invoiceNumber: string;
  onClick(invoice: string): void;
}

const InvoiceForm: React.FC<InvoiceProps> = (props: InvoiceProps) => {
  const [invoice, setInvoice] = useState<string>('');

  useEffect(() => {
    
    setInvoice(props.invoiceNumber)

  }, [props.invoiceNumber]);

  return (
    <div style={styles.container}>
      <PaymentContainer title="Enter Invoice #">
        <TextInput 
          type="string"
          placeholder="Enter invoice number"
          title="INVOICE"
          result={invoice}
          onChange={(event) => setInvoice(event.target.value)} 
        />
        <div style={styles.buttonContainer}>
          <ActionButton name="Submit Invoice" onClick={() => props.onClick(invoice)} fullWidth />
        </div>
      </PaymentContainer>
  </div>
  )
}
export default InvoiceForm;