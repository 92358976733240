import React from 'react';
import PublicSubContainer from '../../containers/PublicSub';

const styles: any = {
  main: {

  }
}

const ContactPage = () => (
  <PublicSubContainer title="about">
    <div className={styles.main}>
        I'm a versatile full-stack developer with experience across multiple platforms and languages. 
    </div>
  </PublicSubContainer>
);

export default ContactPage;
