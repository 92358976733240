import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    color: '#FFFFFF',
    textTransform: 'capitalize',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#212121'
    },
    borderRadius: 0
  },
  secondary: {
    color: '#A5A5A5',
    textTransform: 'capitalize',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#F8F8F8'
  }
});

const useStylesSmall = makeStyles({
  root: {
    color: '#FFFFFF',
    textTransform: 'capitalize'
  },
  secondary: {
    color: '#A5A5A5',
    textTransform: 'capitalize',
    backgroundColor: '#F8F8F8'
  }
});


const styles: any = {
  containerSmall: {
    padding: 10,
    backgroundColor: '#29ABE2',
    borderRadius: 2,
    cursor: 'pointer'
  },
  container: {
    padding: 15,
    backgroundColor: '#29ABE2',
    borderRadius: 2,
    cursor: 'pointer',
  },
  button: {
    color: '#fff',
    textAlign: 'center'
  }
}

interface ActionButtonProps {
  name: string;
  loading?: boolean;
  size?: string;
  type?: string;
  fullWidth?: boolean;
  disabled?:boolean;
  onClick(): void;
}

// const ActionButton = (props: ActionButtonProps) => (
//   <div style={(props.size && props.size === "small" ? styles.containerSmall : styles.container)} onClick={props.onClick}>
//     { (props.loading !== undefined && props.loading) &&
//       <CircularProgress size={15} style={{color: '#FFF', marginLeft: '47%'}} />
//     }
//     { !props.loading &&
//       <div style={styles.button}>{props.name}</div>
//     }
//   </div>
// );

const ActionButton = (props: ActionButtonProps) => {
  const classes = useStyles();
  const classesSmall = useStylesSmall();

  let className: any = classes.root;
  if (props.type && props.type === 'secondary') {
    className = classes.secondary;
  }

  if (props.size && props.size === 'sm') {
    className = classesSmall.root;
    if (props.type && props.type === 'secondary') {
      className = classesSmall.secondary;
    }
  }

  return (
    <Button 
      variant="contained" 
      color="primary" 
      className={className} 
      fullWidth={(props.fullWidth !== undefined ? props.fullWidth : false)} 
      disabled={(props.disabled !== undefined ? props.disabled : false)} 
      onClick={props.onClick}
    >
      {props.name}
    </Button>
  )
};

export default ActionButton;
